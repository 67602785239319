import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import React, { useState } from 'react'

import { Alert } from '@material-ui/lab'
import InfiniteScroll from 'react-infinite-scroller'
import { ListTab } from '../components/ListTab'
import { TruncatedId } from '../../truncated-id'
import { useHistory } from 'react-router'
import { useListTags } from '../hooks/useListTags'

export const ListTags = () => {
  const history = useHistory()
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const input = {
    cursor: null,
    limit: 25
  }

  const { loading, error, data, fetchMore } = useListTags({
    input
  })

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Notification Tags</Typography>
        <FlexHorizontal>
          <Box />
        </FlexHorizontal>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <ListTab activeTab='tags' />
      {loading && !data?.listNotificationTags.notificationTags ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the notification tags</Alert>
      ) : !data?.listNotificationTags.notificationTags.length ? (
        <Alert severity='info'>There are no notification tags</Alert>
      ) : (
        <>
          {data ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={async () => {
                if (isLoadingMore) {
                  return
                }

                setIsLoadingMore(true)
                return await fetchMore({
                  updateQuery(previousQueryResult, options) {
                    const newSearch = options.fetchMoreResult?.listNotificationTags.notificationTags || []
                    const previousSearch = previousQueryResult.listNotificationTags.notificationTags || []

                    return {
                      listNotificationTags: {
                        ...previousQueryResult.listNotificationTags,
                        nextCursor: options.fetchMoreResult?.listNotificationTags.nextCursor || null,
                        notificationTags: [...previousSearch, ...newSearch]
                      }
                    }
                  },
                  variables: {
                    input: {
                      ...input,
                      cursor: data.listNotificationTags.nextCursor || null
                    }
                  }
                }).then(res => {
                  setIsLoadingMore(false)
                  return res
                })
              }}
              hasMore={!!data?.listNotificationTags?.nextCursor}
              loader={
                <Box>
                  <Spacer size='large' />
                  <CircularProgress />
                </Box>
              }>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Tag Group</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.listNotificationTags.notificationTags.map(tag => (
                      <TableRow key={tag.id}>
                        <TableCell>
                          <Button
                            onClick={() => history.push(`/notifications/tag/${tag.id}`)}
                            variant='outlined'
                            color='primary'>
                            <TruncatedId value={tag.id} full />
                          </Button>
                        </TableCell>
                        <TableCell>{tag.title}</TableCell>
                        <TableCell>{tag.tagGroup}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          ) : null}
        </>
      )}
    </Box>
  )
}
