import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import React, { useState } from 'react'

import { Alert } from '@material-ui/lab'
import InfiniteScroll from 'react-infinite-scroller'
import { ListTab } from '../components/ListTab'
import { SearchInput } from '../../../ui/SearchInput'
import { TruncatedId } from '../../truncated-id'
import { useHistory } from 'react-router'
import { useListNotificationSubscribers } from '../hooks/useListNotificationSubscribers'

export const ListSubscribers = () => {
  const [terms, setTerms] = useState('' as string)
  const history = useHistory()
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const input = {
    cursor: null,
    limit: 25,
    terms
  }

  const { loading, error, data, fetchMore } = useListNotificationSubscribers({
    input
  })

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Notification Subscribers</Typography>
        <FlexHorizontal>
          <SearchInput
            onSearch={term => {
              setTerms(term)
            }}
          />
        </FlexHorizontal>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <ListTab activeTab='subscribers' />
      {loading && !data?.listNotificationSubscribers.notificationSubscribers ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the notification subscribers</Alert>
      ) : !data?.listNotificationSubscribers.notificationSubscribers.length ? (
        <Alert severity='info'>There are no notification subscribers</Alert>
      ) : (
        <>
          {data ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={async () => {
                if (isLoadingMore) {
                  return
                }

                setIsLoadingMore(true)
                return await fetchMore({
                  updateQuery(previousQueryResult, options) {
                    const newSearch = options.fetchMoreResult?.listNotificationSubscribers.notificationSubscribers || []
                    const previousSearch = previousQueryResult.listNotificationSubscribers.notificationSubscribers || []

                    return {
                      listNotificationSubscribers: {
                        ...previousQueryResult.listNotificationSubscribers,
                        nextCursor: options.fetchMoreResult?.listNotificationSubscribers.nextCursor || null,
                        notificationSubscribers: [...previousSearch, ...newSearch]
                      }
                    }
                  },
                  variables: {
                    input: {
                      ...input,
                      cursor: data.listNotificationSubscribers.nextCursor || null
                    }
                  }
                }).then(res => {
                  setIsLoadingMore(false)
                  return res
                })
              }}
              hasMore={!!data?.listNotificationSubscribers?.nextCursor}
              loader={
                <Box>
                  <Spacer size='large' />
                  <CircularProgress />
                </Box>
              }>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Unread Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.listNotificationSubscribers.notificationSubscribers.map(subscriber => (
                      <TableRow key={subscriber.id}>
                        <TableCell>
                          <Button
                            onClick={() => history.push(`/notifications/subscriber/${subscriber.id}`)}
                            variant='outlined'
                            color='primary'>
                            <TruncatedId value={subscriber.id} full />
                          </Button>
                        </TableCell>
                        <TableCell>{subscriber.title}</TableCell>
                        <TableCell>{(subscriber.unreadCount || 0).toLocaleString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          ) : null}
        </>
      )}
    </Box>
  )
}
