import {
  ButtonBase,
  CircularProgress,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'

import { Alert } from '@material-ui/lab'
import { FormattedDateTime } from '../../formatted-date-time'
import { Link } from 'react-router-dom'
import React from 'react'
import { TruncatedId } from '../../truncated-id'
import { useAddNotificationTag } from '../hooks/useAddNotificationTag'
import { useGetNotificationSubscriber } from '../hooks/useGetNotificationSubscriber'
import { useListTags } from '../hooks/useListTags'
import { useParams } from 'react-router'
import { useRemoveNotificationTag } from '../hooks/useRemoveNotificationTag'

export const GetSubscriber = () => {
  const params = useParams<{ id: string }>()
  const [addTag] = useAddNotificationTag()
  const [removeTag] = useRemoveNotificationTag()
  const { data, loading, error } = useGetNotificationSubscriber(params.id)
  const { data: tagData } = useListTags({
    input: {
      limit: 100
    }
  })

  return (
    <>
      <Typography variant='button' display='block' gutterBottom>
        <MuiLink component={Link} to='/notifications'>
          Notifications
        </MuiLink>
      </Typography>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Subscription Person</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the subscriber</Alert>
      ) : data?.subscriber.__typename === 'NotificationSubscriberV2' ? (
        <>
          <TableContainer
            component={Paper}
            style={{
              maxWidth: 800
            }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>ID</Typography>
                  </TableCell>
                  <TableCell>
                    <TruncatedId full value={data.subscriber.id} path={`/person/${data.subscriber.id}`} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{data?.subscriber.title}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>Unread Notifications</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{data?.subscriber.unreadCount}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Spacer size='extraLarge' />
          <Typography variant='h5'>Tags</Typography>
          <Spacer size='medium' />
          <TableContainer
            component={Paper}
            style={{
              maxWidth: 800
            }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tagData?.listNotificationTags?.notificationTags.map(tag => {
                  const isSubscribed =
                    data?.subscriber.__typename === 'NotificationSubscriberV2' &&
                    !data?.subscriber.excludedTagIds.some(subscribedTag => subscribedTag === tag.id)

                  return (
                    <TableRow key={tag.id}>
                      <TableCell>
                        {isSubscribed ? (
                          <ButtonBase
                            onClick={async () => {
                              await removeTag({
                                variables: {
                                  input: {
                                    personId: params.id,
                                    tagId: tag.id
                                  }
                                }
                              })
                            }}>
                            <CheckBox />
                          </ButtonBase>
                        ) : (
                          <ButtonBase
                            onClick={async () => {
                              await addTag({
                                variables: {
                                  input: {
                                    personId: params.id,
                                    tagId: tag.id
                                  }
                                }
                              })
                            }}>
                            <CheckBoxOutlineBlank />
                          </ButtonBase>
                        )}
                      </TableCell>
                      <TableCell>
                        <TruncatedId full value={tag.id} path={`/notifications/tag/${tag.id}`} />
                      </TableCell>
                      <TableCell>{tag.title}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Spacer size='extraLarge' />
          <Typography variant='h5'>Notifications</Typography>
          <Spacer size='medium' />
          {data?.subscriber.listNotifications.notifications.length ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Sent At</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Tag</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.subscriber.listNotifications.notifications.map(notification => (
                    <TableRow key={notification.id}>
                      <TableCell>
                        <TruncatedId full value={notification.id} path={`/notifications/message/${notification.id}`} />
                      </TableCell>
                      <TableCell>
                        <FormattedDateTime value={notification.createdAt} />
                      </TableCell>
                      <TableCell>{notification.title}</TableCell>
                      <TableCell>{notification.tag?.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert severity='info'>This subscriber has no notifications</Alert>
          )}
        </>
      ) : (
        <Alert severity='error'>{data?.subscriber.error || 'There was an error loading the subscriber'}</Alert>
      )}
    </>
  )
}
