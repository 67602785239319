import { GetTagSubscribers, GetTagSubscribersVariables } from './__generated__/GetTagSubscribers'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetTagSubscribers($id: String!) {
    notificationTag: notificationTagV2(id: $id) {
      ... on NotificationTagV2 {
        id
        title
        tagGroup
      }
      ... on NotificationTagErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetTagSubscribers = (variables: GetTagSubscribersVariables) =>
  useQuery<GetTagSubscribers, GetTagSubscribersVariables>(QUERY, { variables })
