import {
  CircularProgress,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'

import { Alert } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import React from 'react'
import { TruncatedId } from '../../truncated-id'
import { useGetTagSubscribers } from '../hooks/useGetTagSubscribers'
import { useParams } from 'react-router'

export const Tag = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useGetTagSubscribers({
    id
  })

  return (
    <>
      <Typography variant='button' display='block' gutterBottom>
        <MuiLink component={Link} to='/notifications'>
          Notifications
        </MuiLink>
      </Typography>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Tag</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the tag</Alert>
      ) : data?.notificationTag.__typename === 'NotificationTagErrorV2' ? (
        <Alert severity='error'>{data.notificationTag.error || 'There was an error'}</Alert>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>ID</Typography>
                  </TableCell>
                  <TableCell>
                    <TruncatedId value={data?.notificationTag.id || ''} full />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>Title</Typography>
                  </TableCell>
                  <TableCell>{data?.notificationTag.title}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>Tag Group</Typography>
                  </TableCell>
                  <TableCell>{data?.notificationTag.tagGroup}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}
